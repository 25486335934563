<template>
  <div>
    <SliceZone
      wrapper="main"
      :slices="page?.data.slices ?? []"
      :components="components"
    />
  </div>
</template>

<script setup lang="ts">
import { components } from "~/slices";

const prismic = usePrismic();
const config = useRuntimeConfig();

const { data: page } = useAsyncData("index", () =>
  prismic.client.getSingle("home"),
);

useSeoMeta({
  description: () => page.value?.data.meta_description,
  ogTitle: () => page.value?.data.meta_title,
  ogUrl: () => config.public.BASE_URL,
  ogDescription: () => page.value?.data.meta_description,
  ogImage: () => page.value?.data.meta_image.url ?? `${config.public.BASE_URL}/lightbox_gallery.jpg`,
  ogImageType: () => "image/jpeg",
  ogImageWidth: () => "1200",
  ogImageHeight: () => "630",
  ogType: () => "website",
  twitterTitle: () => page.value?.data.meta_title,
  twitterImage: () => page.value?.data.meta_image.url ?? `${config.public.BASE_URL}/lightbox_gallery.jpg`,
  twitterDescription: () => page.value?.data.meta_description,
});
</script>
